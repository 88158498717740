<template>
    <div>
        <ts-page-title
            :title="$t('holiday.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('holiday.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-flex">
                        <Button type="info" ghost @click="addNew">{{
                            $t("addNew")
                        }}</Button>
                        <DatePicker
                            :value="cycle_year"
                            @on-change="onChange"
                            format="yyyy"
                            type="year"
                            style="width: 200px;"
                            class="tw-px-2"
                        />
                        <ts-button
                            color="primary"
                            :waiting="generating"
                            class="tw-px-2"
                            style="width: 200px;"
                            @click="generate()"
                        >
                            {{ $t("holiday.generate") }}</ts-button
                        >
                    </div>
                    <div class="tw-justify-end tw-space-x-2">
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('holiday.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                    </div>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>

            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('holiday.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import moment from "moment";
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";

export default {
    name: "holiday",
    components: {
        FromAction
    },
    data() {
        return {
            cycle_year: moment()
                .year()
                .toString(),
            loading: false,
            showForm: false,
            generating: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("humanResource/holiday", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.humanResource.holiday.search;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/holiday/SET_SEARCH",
                    newValue
                );
                this.$store.commit("humanResource/holiday/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                {
                    title: this.$t("holiday.holidayDate"),
                    key: "holiday_date"
                },
                {
                    title: this.$t("holiday.holidayNameKh"),
                    key: "holiday_name_kh"
                },
                {
                    title: this.$t("holiday.holidayNameEn"),
                    key: "holiday_name_en"
                },
                {
                    title: this.$t("holiday.holidayDescription"),
                    key: "holiday_description"
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 90
                }
            ];
        }
    },
    methods: {
        onChange(data) {
            this.cycle_year = data;
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("humanResource/holiday/fetch", {
                    cycle_year: this.cycle_year,
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        generate() {
            this.generating = true;
            this.$store
                .dispatch("humanResource/holiday/generate", {
                    cycle_year: this.cycle_year
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.fetchData();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.generating = false;
                });
        },
        onEdit(record) {
            this.$store.commit("humanResource/holiday/SET_EDIT_DATA", record);
            this.showForm = true;
            this.$refs.form_action.setEditData();
        },
        clearEdit() {
            this.showForm = false;
            this.$refs.form_action.setEditData();
            this.$refs.form_action.clearInput();
            this.$store.commit("humanResource/holiday/SET_EDIT_DATA", {});
        },
        addNew() {
            this.$store.commit("humanResource/holiday/SET_EDIT_DATA", {});
            this.showForm = true;
            this.$refs.form_action.setEditData();
            this.$refs.form_action.clearInput();
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "HOLIDAY",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500),
        cycle_year: function(cycleYear) {
            this.fetchData({ cycle_year: cycleYear });
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("humanResource/holiday/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
