<template>
    <div>
        <div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("holiday.holidayNameKh")
                    }}</label>
                    <input
                        v-model.trim="model.holiday_name_kh"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('holiday_name_kh') }"
                        ref="holiday_name_kh"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('holiday_name_kh')"
                    >
                        {{ errors.first("holiday_name_kh") }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("holiday.holidayNameEn")
                    }}</label>
                    <input
                        v-model.trim="model.holiday_name_en"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('holiday_name_en') }"
                        ref="holiday_name_en"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('holiday_name_en')"
                    >
                        {{ errors.first("holiday_name_en") }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("holiday.holidayDate")
                    }}</label>
                    <DatePicker
                        format="yyyy-MM-dd"
                        placement="bottom-start"
                        @on-change="onChange"
                        :value="model.holiday_date"
                        type="datetime"
                        style="width: 100%;"
                        :class="{ 'is-invalid': errors.has('holiday_date') }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('holiday_date')"
                    >
                        {{ errors.first("holiday_date") }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                        $t("holiday.holidayDescription")
                    }}</label>
                    <Input
                        v-model.trim="model.holiday_description"
                        type="textarea"
                    />
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "holiday-form",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                holiday_date: null,
                holiday_name_kh: null,
                holiday_name_en: null,
                holiday_description: null
            }
        };
    },
    computed: {
        ...mapState("humanResource/holiday", ["edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        onChange(data) {
            this.model.holiday_date = data;
        },
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("humanResource/holiday/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/holiday/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("humanResource/holiday/update", {
                    id: this.edit_data.record_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function() {
                this.$refs.holiday_name_kh.focus();
            });
            if (!isEmpty(this.edit_data)) {
                console.log(this.edit_data);
                this.model.holiday_name_kh = this.edit_data.holiday_name_kh;
                this.model.holiday_name_en = this.edit_data.holiday_name_en;
                this.model.holiday_description = this.edit_data.holiday_description;
                this.model.holiday_date = this.edit_data.holiday_date;
                console.log(this.model.holiday_date);
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.holiday_name_kh = null;
            this.model.holiday_name_en = null;
            this.model.holiday_description = null;
            this.model.holiday_date = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "HOLIDAY",
                desc: not.text
            });
        }
    }
};
</script>
